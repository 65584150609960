<template>
  <div>
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-row class="mt-1">
        <b-col lg="8" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Oda Kayıtları</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="myHr">
            <table
                :key="count"
                class="table table-bordered mt-1"
            >
              <thead>
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  KOD
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  ODA TÜRÜ
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  KAT
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  KAPASİTE
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  PAYLAŞIM
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  ONLİNE R.
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  RENK
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"/>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in this.$store.getters.odaList"
                  :key="index"
              >
                <td>{{ item.odaKodu }}</td>
                <td>{{ item.odaTipi }}</td>
                <td>{{ item.kat }}</td>
                <td>Yatak: {{ item.yatak }} - Ek Yatak: {{ item.eYatak }}</td>
                <td>
                  <b-form-checkbox
                      checked="true"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      :value="item.paylasimli == 1 ? true : false"
                      @change="paylasimSet(item.odaID)"
                  >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon"/>
                      </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon"/>
                      </span>
                  </b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox
                      checked="true"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      :value="item.online == 1 ? true : false"
                      @change="onlineSet(item.odaID)"
                  >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon"/>
                      </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon"/>
                      </span>
                  </b-form-checkbox>
                </td>
                <td>
                  <div
                      class="myColor"
                      :class="'bg-' + $store.getters.odaParams(item.odaRenk)"
                  />
                </td>
                <td>
                  <b-button
                      v-b-tooltip.hover.top="'Düzenle'"
                      variant="bgInfo"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      @click="odaGuncelle(item)"
                  >
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                  <b-button
                      v-b-tooltip.hover.right="'İçeriği Kopyala'"
                      variant="bgPrimary"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      @click="duplicate(item)"
                  >
                    <feather-icon icon="CopyIcon"/>
                  </b-button>
                  <b-button
                      v-b-tooltip.hover.right="'Sil'"
                      variant="bgDanger"
                      class="btn-icon rounded-circle"
                      size="sm"
                      @click="odaRemove(item)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
        <b-col lg="4" cols="12">
          <b-card-actions
              class="cari-liste-card editCard"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block"> {{
                      isEdit == false
                          ? 'Oda Kaydı'
                          : howEditRoom + ' Nolu Oda Düzenleniyor'
                    }}</h4>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="bgInfo"
                      class="myButton border-0 float-right"
                      to="/odaYonetimTur"
                      :style="{backgroundColor:$store.getters.cardTitleButton}"
                  >
                    <feather-icon
                        icon="ListIcon"
                        class="mr-50"
                    />
                    Oda Türler
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <hr>
            <b-form-group
                label="Oda Kodu"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="odaKodu"
                  placeholder="Oda Kodu"
                  @keyup.enter="gallerySave"
                  autocomplete="off"
              />
            </b-form-group>
            <b-form-group
                label="Kat"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="kat"
                  placeholder="Bulunduğu Kat"
                  @keyup.enter="gallerySave"
                  autocomplete="off"
              />
            </b-form-group>
            <b-form-group
                label="Yatak"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="yatak"
                  placeholder="Yatak Sayısı"
                  @keyup.enter="gallerySave"
                  autocomplete="off"
              />
            </b-form-group>
            <b-form-group
                label="Ek Yatak"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="ekYatak"
                  placeholder="Ek Yatak Sayısı"
                  @keyup.enter="gallerySave"
                  autocomplete="off"
              />
            </b-form-group>
            <b-form-group label="Oda Türü">
              <v-select
                  v-model="odaTuru"
                  :components="{ OpenIndicator, Deselect }"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="adi"
                  :options="this.$store.getters.odaTurleri"
              />
            </b-form-group>
            <b-form-group
                label="Oda Rengi"
                label-for="basicInput"
                class="mt-1"
            >
              <v-select
                  v-model="odaRenk"
                  :components="{ OpenIndicator, Deselect }"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="odaRenkOption"
              />
            </b-form-group>
            <b-form-group
                label="Oda Paylaşım Ayarı"
                label-for="basicInput"
                class="mt-1"
            >
              <v-select
                  v-model="odaPaylasim"
                  :components="{ OpenIndicator, Deselect }"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="odaPaylasimOption"
              />
            </b-form-group>
            <b-form-group
                label="Online Rezervasyon"
                label-for="basicInput"
                class="mt-1"
            >
              <v-select
                  v-model="onlineRezervasyon"
                  :components="{ OpenIndicator, Deselect }"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="onlineRezervasyonOption"
              />
            </b-form-group>
            <b-row class="text-right">
              <b-button
                  v-show="!isEdit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="mt-1 mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="odaSave"
              >
                Kaydet
              </b-button>
              <b-button
                  v-show="!isEdit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgPrimary"
                  class="mt-1 mr-1"
                  @click="reset"
              >
                Sıfırla
              </b-button>
              <b-button
                  v-show="isEdit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="mt-1 mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="odaUpdate"
              >
                Kaydet
              </b-button>
              <b-button
                  v-show="isEdit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mt-1 mr-1 border-none text-white"
                  :style="{backgroundColor:$store.getters.bgDanger}"
                  @click="editClosed"
              >
                Vazgeç
              </b-button>
            </b-row>
            <b-row :class="{ editOnPrgoress: isEdit }"/>
          </b-card-actions>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  VBTooltip,
  BProgress,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BCardActions,
    BSpinner,
    VBTooltip,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    quillEditor,
    BFormFile,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      spinner: true,
      Deselect: {
        render: createElement => createElement('span', ''),
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      isEdit: false,
      howEditRoom: '',
      chooseID: '',
      count: 0,
      odaKodu: '',
      kat: '',
      yatak: '',
      ekYatak: '',
      odaTuru: {
        adi: 'Oda Türü Seçiniz',
        key: 0
      },
      odaTuruOption: [
        {
          title: 'Oda Türü Seçiniz',
          key: 0
        },
        {
          title: 'French oda',
          key: 1
        },
        {
          title: 'Twin Oda',
          key: 2
        },
        {
          title: 'Triple Oda',
          key: 3
        },
        {
          title: 'Suit Oda',
          key: 4
        },
        {
          title: 'Çoklu Oda',
          key: 5
        },
      ],
      odaPaylasim: {
        title: 'Paylaşıma Kapalı',
        key: 0
      },
      odaPaylasimOption: [
        {
          title: 'Paylaşıma Kapalı',
          key: 0
        },
        {
          title: 'Paylaşımlı Oda',
          key: 1
        },
      ],
      odaRenk: {
        title: 'Oda Rengi Seçiniz',
        key: 0
      },
      odaRenkOption: [
        {
          title: 'Oda Rengi Seçiniz',
          key: 0
        },
        {
          title: 'Mor',
          key: 1
        },
        {
          title: 'Turkuaz',
          key: 2
        },
        {
          title: 'Mavi',
          key: 3
        },
        {
          title: 'Kırmızı',
          key: 4
        },
        {
          title: 'Sarı',
          key: 5
        },
      ],
      onlineRezervasyon: {
        title: 'Online Rez. Kapalı',
        key: 0
      },
      onlineRezervasyonOption: [
        {
          title: 'Online Rez. Kapalı',
          key: 0
        },
        {
          title: 'Online Rez. Açık',
          key: 1
        },
      ],
    }
  },
  computed: {
    galeriDetayImage() {
      return this.$store.state.webPanel.galleryDetails.liste
    },
    salaryListItems() {
      return this.$store.state.webPanel.gallery
    },
  },
  created() {
    this.$store
        .dispatch('odaYonetim')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearOdaYonetimi')
  },
  methods: {
    duplicate(item) {
      this.isEdit = false
      const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      this.odaKodu = ''
      this.kat = item.kat
      this.yatak = item.yatak
      this.ekYatak = item.eYatak
      const tur = Object.values(this.$store.getters.odaTurleri)
          .find(obj => obj.adi == item.odaTipi)
      this.odaTuru = tur
      this.odaPaylasim = item.paylasimli == 0
          ? {
            title: 'Paylaşıma Kapalı',
            key: 0
          }
          : {
            title: 'Paylaşımlı Oda',
            key: 1
          }
      this.odaRenk = item.odaRenk == 0
          ? {
            title: 'Oda Rengi Seçiniz',
            key: 0
          }
          : item.odaRenk == 1
              ? {
                title: 'Mor',
                key: 1
              }
              : item.odaRenk == 2
                  ? {
                    title: 'Turkuaz',
                    key: 2
                  }
                  : item.odaRenk == 3
                      ? {
                        title: 'Mavi',
                        key: 3
                      }
                      : item.odaRenk == 4
                          ? {
                            title: 'Kırmızı',
                            key: 4
                          }
                          : item.odaRenk == 5
                              ? {
                                title: 'Sarı',
                                key: 5
                              }
                              : {
                                title: 'Oda Rengi Seçiniz',
                                key: 0
                              }
      this.onlineRezervasyon = item.online == 0
          ? {
            title: 'Online Rez. Kapalı',
            key: 0
          }
          : {
            title: 'Online Rez. Açık',
            key: 1
          }
    },
    reset() {
      this.odaKodu = ''
      this.kat = ''
      this.yatak = ''
      this.ekYatak = ''
      this.odaTuru = {
        adi: 'Oda Türü Seçiniz',
        key: 0
      }
      this.odaPaylasim = {
        title: 'Paylaşıma Kapalı',
        key: 0
      }
      this.odaRenk = {
        title: 'Oda Rengi Seçiniz',
        key: 0
      }
      this.onlineRezervasyon = {
        title: 'Online Rez. Kapalı',
        key: 0
      }
    },
    odaSave() {
      if (this.odaKodu) {
        if (this.odaRenk.key != 0) {
          this.$store
              .dispatch('odaKaydi', {
                odaKodu: this.odaKodu,
                kat: this.kat,
                yatak: this.yatak,
                eYatak: this.ekYatak,
                tel: this.odaKodu,
                tip: this.odaTuru.tip,
                paylasimli: this.odaPaylasim.key,
                renk: this.odaRenk.key,
                online: this.onlineRezervasyon.key,
              })
              .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
                (this.odaTuru = {
                  adi: 'Oda Türü Seçiniz',
                  key: 0
                }),
                    (this.odaPaylasim = {
                      title: 'Paylaşıma Kapalı',
                      key: 0
                    }),
                    (this.odaRenk = {
                      title: 'Oda Rengi Seçiniz',
                      key: 0
                    }),
                    (this.onlineRezervasyon = {
                      title: 'Online Rez. Kapalı',
                      key: 0,
                    }),
                    (this.odaKodu = ''),
                    (this.kat = ''),
                    (this.yatak = ''),
                    (this.ekYatak = '')
                const mesaj = this.$store.getters.notificationSettings(res)
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              })
              .catch(err => {
                const mesaj = this.$store.getters.notificationSettings(err)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Hata!',
              text: 'Oda Rengi Zorunludur',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata!',
            text: 'Oda Kodu Boş Bırakılamaz',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    },
    odaUpdate() {
      if (this.isEdit) {
        this.$store
            .dispatch('odaUpdate', {
              odaKodu: this.odaKodu,
              kat: this.kat,
              yatak: this.yatak,
              eYatak: this.ekYatak,
              tel: this.odaKodu,
              tip: this.odaTuru.tip,
              paylasimli: this.odaPaylasim.key,
              renk: this.odaRenk.key,
              online: this.onlineRezervasyon.key,
              odaID: this.chooseID,
            })
            .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
              this.isEdit = false;
              (this.odaTuru = {
                adi: 'Oda Türü Seçiniz',
                key: 0
              }),
                  (this.odaPaylasim = {
                    title: 'Paylaşıma Kapalı',
                    key: 0
                  }),
                  (this.odaRenk = {
                    title: 'Oda Rengi Seçiniz',
                    key: 0
                  }),
                  (this.onlineRezervasyon = {
                    title: 'Online Rez. Kapalı',
                    key: 0,
                  }),
                  (this.odaKodu = ''),
                  (this.kat = ''),
                  (this.yatak = ''),
                  (this.ekYatak = '')
              const mesaj = this.$store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Başarılı',
                        text: mesaj,
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    },
                    { position },
                )
              }
            })
            .catch(err => {
              const mesaj = this.$store.getters.notificationSettings(err)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Oda Güncelleme Başarısız',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    },
    onlineSet(odaNo) {
      this.$store
          .dispatch('odaOnlineDurumChange', {
            id: odaNo,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
      this.count += 1
    },
    paylasimSet(odaNo) {
      this.$store
          .dispatch('odaDurumChange', {
            id: odaNo,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
      this.count += 1
    },
    galleryDetails(gal) {
      this.$store.dispatch('galleryDetails', {
        galeriID: gal.galeriID,
      })
    },
    odaGuncelle(item) {
      const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      this.howEditRoom = item.odaKodu
      this.isEdit = true
      this.chooseID = item.odaID
      this.odaKodu = item.odaKodu
      this.kat = item.kat
      this.yatak = item.yatak
      this.ekYatak = item.eYatak
      const tur = Object.values(this.$store.getters.odaTurleri)
          .find(obj => obj.adi == item.odaTipi)
      this.odaTuru = tur
      this.odaPaylasim = item.paylasimli == 0
          ? {
            title: 'Paylaşıma Kapalı',
            key: 0
          }
          : {
            title: 'Paylaşımlı Oda',
            key: 1
          }
      this.odaRenk = item.odaRenk == 0
          ? {
            title: 'Oda Rengi Seçiniz',
            key: 0
          }
          : item.odaRenk == 1
              ? {
                title: 'Mor',
                key: 1
              }
              : item.odaRenk == 2
                  ? {
                    title: 'Turkuaz',
                    key: 2
                  }
                  : item.odaRenk == 3
                      ? {
                        title: 'Mavi',
                        key: 3
                      }
                      : item.odaRenk == 4
                          ? {
                            title: 'Kırmızı',
                            key: 4
                          }
                          : item.odaRenk == 5
                              ? {
                                title: 'Sarı',
                                key: 5
                              }
                              : {
                                title: 'Oda Rengi Seçiniz',
                                key: 0
                              }
      this.onlineRezervasyon = item.online == 0
          ? {
            title: 'Online Rez. Kapalı',
            key: 0
          }
          : {
            title: 'Online Rez. Açık',
            key: 1
          }
    },
    editClosed() {
      this.isEdit = false
      this.howEditRoom = '';
      (this.odaTuru = {
        adi: 'Oda Türü Seçiniz',
        key: 0
      }),
          (this.odaPaylasim = {
            title: 'Paylaşıma Kapalı',
            key: 0
          }),
          (this.odaRenk = {
            title: 'Oda Rengi Seçiniz',
            key: 0
          }),
          (this.onlineRezervasyon = {
            title: 'Online Rez. Kapalı',
            key: 0,
          }),
          (this.odaKodu = ''),
          (this.kat = ''),
          (this.yatak = ''),
          (this.ekYatak = '')
    },
    galleryUpdate() {
      this.$store
          .dispatch('galleryUpdate', {
            galeriAdi: this.galleryUname,
            galeriID: this.galleryUid,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$bvModal.hide('galleryEdit')
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    galleryDetailsRemove(val) {
      this.$store
          .dispatch('galleryDetailsRemove', {
            galeriID:
            this.$store.state.webPanel.galleryDetails.galeriDetay.galeriID,
            sil: val,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    odaRemove(val) {
      this.$swal({
        title: `${val.odaKodu} Nolu Odayı Silmek İstediğinize Eminmisiniz?`,
        text: 'Bu İşlem Geri Alınamaz!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil!',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store
                  .dispatch('odaSil', { id: val.odaID })
                  .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
                    const mesaj = this.$store.getters.notificationSettings(res)
                    if (mesaj) {
                      this.$toast(
                          {
                            component: ToastificationContent,
                            props: {
                              title: 'Başarılı',
                              text: mesaj,
                              icon: 'ThumbsUpIcon',
                              variant: 'success',
                            },
                          },
                          { position },
                      )
                    }
                  })
                  .catch(err => {
                    const mesaj = this.$store.getters.notificationSettings(err)
                    if (mesaj) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata!',
                          text: mesaj,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
            }
          })
    },
    clearDetails() {
      this.$store.commit('clearGalleryDetails')
    },
  },
}
</script>

<style scoped>
@keyframes mymove {
  0% {
    background-color: pink;
  }

  25% {
    background-color: pink;
  }

  50% {
    background-color: #00ffd1;
  }

  75% {
    background-color: #00ffd1;
  }

  100% {
    background-color: #00ffd1;
    width: 480px;
  }
}

.editOnPrgoress {
  width: 0px;
  height: 4px;
  background: #38e54d;
  position: relative;
  animation: mymove 4000ms infinite;
  border-radius: 20px;
  transform: translateY(20px);
  /* animation-iteration-count: 1 !important; */
}

.odaKayitRow {
  justify-content: space-around !important;
}

.table td {
  text-align: center !important;
}

.myColor {
  width: 30px;
  height: 30px;
  border-radius: 14px;
  margin: 0 auto !important;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #31526f;
  color: white;
  padding: 0.6rem;
  border: none;
  text-align: center;
}

[dir] .table td {
  padding: 0.1rem !important;
}

.row {
  justify-content: end;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  padding: 3px !important;
  color: white !important;
}

.card-body {
  padding: 10px 15px;
}

.myHr {
  margin-top: 0 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.editCard {
  border: inherit !important;
}

@keyframes editCard {
  from {
    background-color: red !important;
  }

  to {
    background-color: yellow !important;
  }
}
</style>
