import { render, staticRenderFns } from "./odaYonetimi.vue?vue&type=template&id=2a34d817&scoped=true&"
import script from "./odaYonetimi.vue?vue&type=script&lang=js&"
export * from "./odaYonetimi.vue?vue&type=script&lang=js&"
import style0 from "./odaYonetimi.vue?vue&type=style&index=0&id=2a34d817&scoped=true&lang=css&"
import style1 from "./odaYonetimi.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./odaYonetimi.vue?vue&type=style&index=2&id=2a34d817&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a34d817",
  null
  
)

export default component.exports